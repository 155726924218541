import React from 'react'
import {GatsbySEO} from '~/gatsby/components/seo'
import {createReplace} from '../../renderer'
import {linkedItems} from '../../util'
import {KenticoBodyItem} from '../body-item'
import {KenticoGlobalScriptData} from '../global-script'
import {KenticoPageData} from './data'

interface Props {
  page: KenticoPageData
  globalScripts: KenticoGlobalScriptData
}

/** Kentico type data. */
export {KenticoPageData}

/**
 * Render page from Kentico data.
 * @return React component
 */
export const KenticoPage = ({page, globalScripts}: Props) => (
  <>
    <GatsbySEO
      description={page.elements.description.value}
      globalScripts={globalScripts}
      page={page}
      pageScripts={page.elements.scripts}
      replace={createReplace}
      robots={page.elements.robots}
      schema={linkedItems(page.elements.schema)}
      title={page.elements.title.value}
    />
    <KenticoBodyItem data={linkedItems(page.elements.body)} />
  </>
)
