import {graphql} from 'gatsby'
import * as IO from 'io-ts'
import React from 'react'
import {KenticoGlobalScriptData} from '~/kentico/components/global-script'
import {KenticoPage, KenticoPageData} from '~/kentico/components/page'
import {CookieError} from '~/common/components/cookie-error'
import {GatsbyLayout} from '../components/layout'
import {useDecode} from '../util'

const Props = IO.type({
  data: IO.type({
    globalScripts: KenticoGlobalScriptData,
    page: KenticoPageData,
  }),
})

/** Query from template. */
export const query = graphql`
  query($id: String!) {
    page: kontentItemPage(id: {eq: $id}) {
      ...KenticoPage
    }
    ...KenticoGlobalScripts
  }
`

const Page = (props: unknown) => {
  const {data} = useDecode(Props, props)
  return (
    <GatsbyLayout contentId={data.page.system.id}>
      <CookieError />
      <KenticoPage {...data} />
    </GatsbyLayout>
  )
}

/** Page. */
// eslint-disable-next-line import/no-default-export
export default Page
